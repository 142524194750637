import { template as template_91742ccdb0664ee4968bd4b9a0500b62 } from "@ember/template-compiler";
const FKLabel = template_91742ccdb0664ee4968bd4b9a0500b62(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
