import { template as template_12ccae834b284ef4a25560834a6b525b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_12ccae834b284ef4a25560834a6b525b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
