import { template as template_02f0a65b4092431e8ef9763e82c36573 } from "@ember/template-compiler";
const FKControlMenuContainer = template_02f0a65b4092431e8ef9763e82c36573(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
