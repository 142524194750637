import { template as template_520118ab7e3b4debb06a9b74cd07cffd } from "@ember/template-compiler";
const FKText = template_520118ab7e3b4debb06a9b74cd07cffd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
